import axios from 'axios';
const apiUrl = 'https://geoapi.heartrails.com/api/json?method=getPrefectures';

axios.defaults.headers = {
	'Cache-Control': 'no-cache',
	Pragma: 'no-cache',
	Expires: '0',
};

const prefectureJPService = {
	/**
	 * Get All JP Prefecture
	 * @returns Array
	 */
	async get() {
		try {
			return await axios.get(apiUrl);
		} catch (error) {
			throw new Error(`PrefectureJPService: ${error}`);
		}
	},
};

export default prefectureJPService;
