<template>
	<div id="management-master">
		<div class="screen-title">管理マスタ</div>
		<b-container fluid>
			<validation-observer class="w-100" v-slot="{ handleSubmit }" ref="formValidator">
				<b-form class="w-100" role="form" @submit.prevent="handleSubmit(onSave)">
					<b-row class="mx-0 d-flex row-gap-20">
						<card class="my-0 w-100">
							<div class="basic-information-container">
								<div class="card-title">基本情報</div>
								<b-row class="custom__mx-3 col-gap-30 pb-27">
									<div class="col-md col-xs-12 px-0 res_margin_bottom">
										<base-input
											alternative
											placeholder="会社名"
											name="会社名"
											v-model="model.companyName"
											label="会社名"
											:labelClasses="'custom-form-input-label'"
											:inputClasses="'custom-input-class'"
										>
										</base-input>
									</div>
									<div class="col-md col-xs-12 px-0">
										<base-input
											alternative
											placeholder="代表者名"
											name="代表者名"
											v-model="model.ceoName"
											label="代表者名"
											:labelClasses="'custom-form-input-label'"
											:inputClasses="'custom-input-class'"
										>
										</base-input>
									</div>
								</b-row>
								<b-row class="custom__mx-3 col-gap-30 pb-27">
									<div class="col-md col-xs-12 px-0 res_margin_bottom">
										<!-- <base-input
											alternative
											placeholder="郵便番号"
											name="郵便番号"
											:value="model.postcode"
											label="郵便番号"
											type="text"
											@keydown="onKeyDownPostalCode"
											@input="onPostalCodeInput"
											:numberCaption="true"
											:labelClasses="'custom-form-input-label'"
											:inputClasses="'custom-input-class'"
										>
										</base-input> -->

										<!-- An added Area -->
										<base-input
											alternative
											placeholder="郵便番号"
											name="郵便番号"
											v-model="model.postcodeAddressJapan"
											@blur="onBlurPostcode"
											label="郵便番号"
											type="text"
											:numberCaption="true"
											:labelClasses="'custom-form-input-label'"
											:noteError="errorNoteMsg"
										>
										</base-input>
										<!-- An added Area -->
									</div>
									<div class="col-md col-xs-12 px-0">
										<base-input
											alternative
											placeholder="住所"
											name="住所"
											v-model="model.address"
											label="住所"
											:labelClasses="'custom-form-input-label'"
											:inputClasses="'custom-input-class'"
										>
										</base-input>
									</div>
								</b-row>
								<b-row class="custom__mx-3 col-gap-30 pb-27">
									<div class="col-md col-xs-12 px-0 res_margin_bottom">
										<base-input
											alternative
											placeholder="電話番号"
											name="電話番号"
											v-model="model.phoneNumber"
											label="電話番号"
											type="text"
											@keydown="onKeyDown"
											@blur="checkPhoneNumber"
											:numberCaption="true"
											:labelClasses="'custom-form-input-label'"
											:inputClasses="'custom-input-class'"
										>
										</base-input>
									</div>
									<div class="col-md col-xs-12 px-0">
										<base-input
											alternative
											placeholder="FAX番号"
											name="FAX番号"
											v-model="model.faxNumber"
											label="FAX番号"
											type="text"
											@keydown="onKeyDown"
											@blur="checkFaxNumber"
											:numberCaption="true"
											:labelClasses="'custom-form-input-label'"
											:inputClasses="'custom-input-class'"
										>
										</base-input>
									</div>
								</b-row>
								<b-row class="custom__mx-3 col-gap-30 pb-27">
									<div class="col-md col-xs-12 px-0 res_margin_bottom">
										<base-input
											alternative
											placeholder="フッターメールアドレス"
											name="フッターメールアドレス"
											v-model="model.footerMailAddress"
											label="フッターメールアドレス"
											:rules="{ required: true, email: true }"
											:inputRequired="true"
											:labelClasses="'custom-form-input-label'"
											:inputClasses="'custom-input-class'"
										>
										</base-input>
									</div>
									<div class="col-md col-xs-12 px-0">
										<base-input
											alternative
											placeholder="fromメールアドレス"
											name="fromメールアドレス"
											v-model="model.fromMailAddress"
											label="fromメールアドレス"
											:rules="{ required: true, email: true }"
											:inputRequired="true"
											:labelClasses="'custom-form-input-label'"
											:inputClasses="'custom-input-class'"
										>
										</base-input>
									</div>
								</b-row>
								<b-row class="custom__mx-3 col-gap-30 pb-27">
									<div class="col-md col-xs-12 px-0 res_margin_bottom">
										<base-input
											alternative
											placeholder="お問い合わせメール１"
											name="お問い合わせメール１"
											v-model="model.inquiryMail_1"
											label="お問い合わせメール１"
											:rules="{ required: true, email: true }"
											:inputRequired="true"
											:labelClasses="'custom-form-input-label'"
											:inputClasses="'custom-input-class'"
										>
										</base-input>
									</div>
									<div class="col-md col-xs-12 px-0 res_margin_bottom">
										<base-input
											alternative
											placeholder="お問い合わせメール２"
											name="お問い合わせメール２"
											v-model="model.inquiryMail_2"
											label="お問い合わせメール２"
											:rules="{ email: true }"
											:labelClasses="'custom-form-input-label'"
											:inputClasses="'custom-input-class'"
										>
										</base-input>
									</div>
									<div class="col-md col-xs-12 px-0">
										<base-input
											alternative
											placeholder="お問い合わせメール３"
											name="お問い合わせメール３"
											v-model="model.inquiryMail_3"
											label="お問い合わせメール３"
											:rules="{ email: true }"
											:labelClasses="'custom-form-input-label'"
											:inputClasses="'custom-input-class'"
										>
										</base-input>
									</div>
								</b-row>
							</div>
						</card>
						<card class="my-0 w-100">
							<div class="card-title">口座情報</div>
							<b-row class="custom__mx-3 col-gap-30 pb-27">
								<div
									class="col-md d-flex align-items-center col-gap-10 col-xs-12 res_margin_bottom px-0"
								>
									<base-input
										alternative
										placeholder="銀行名"
										name="銀行名"
										v-model="model.bankName"
										label="銀行名"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
										:datalist="autoBanks"
										@on-select-option="onSelectOption"
									>
									</base-input>
									<div class="bank-caption">銀行</div>
								</div>
								<div class="col-md col-xs-12 px-0 d-flex align-items-center col-gap-10">
									<base-input
										alternative
										placeholder="支店名"
										name="支店名"
										v-model="model.bankBranchName"
										label="支店名"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
										:datalist="autoBranches"
										@click="getBranchName(model.bankBranchName, bankCode)"
										@on-branch-empty="onBankEmpty"
										@on-select-option="onSelectOption"
									>
									</base-input>
									<div class="bank-caption">支店</div>
								</div>
							</b-row>
							<b-row class="custom__mx-3 col-gap-30 pb-27">
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										label="口座種別"
										name="口座種別"
										:disabled="true"
										:labelClasses="'custom-form-input-label'"
									>
										<b-form-radio-group v-model="model.bankAccountType">
											<b-form-radio
												class="custom-control-inline custom__mr-15"
												v-for="item in bankAccountTypeOptions"
												v-bind="bankAccountTypeOptions"
												:key="item.value"
												:value="item.value"
											>
												{{ item.value }}</b-form-radio
											>
										</b-form-radio-group>
									</base-input>
								</div>
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										placeholder="口座名義人"
										name="口座名義人"
										v-model="model.bankAccountHolder"
										label="口座名義人"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
										@input="onBankAccountHolderChange"
									>
									</base-input>
								</div>
								<div class="col-md col-xs-12 px-0">
									<base-input
										alternative
										placeholder="口座番号"
										name="口座番号"
										v-model="model.bankAccountNumber"
										label="口座番号"
										type="number"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
										@keypress="return isNumeric($event);"
										@input="maxLengthCheck()"
									>
									</base-input>
								</div>
							</b-row>
						</card>
						<card class="my-0 w-100">
							<div class="card-title">管理情報</div>
							<b-row class="custom__mx-3 col-gap-20 pb-27 d-flex align-items-center">
								<div class="elm-title">消費税率</div>
								<input
									placeholder="消費税率"
									name=""
									v-model="model.taxRate"
									type="number"
									class="custom-input-number-mid"
								/>
								<div class="elm-title">%</div>
							</b-row>
						</card>
						<card class="my-0 w-100">
							<div class="card-title">備考</div>
							<textarea
								class="form-control"
								type="text"
								v-model="model.remark"
								style="resize: none"
								rows="5"
							></textarea>
						</card>
					</b-row>
					<b-row class="my-4 w-100 d-flex justify-content-center">
						<button class="customButton customButton__submit" native-type="submit">
							変更
						</button>
					</b-row>
				</b-form>
			</validation-observer>
		</b-container>
		<NotiModal
			:notificationModalId="notiModalId"
			:notiContent="notiContent"
			:single-btn="notiSingleBtn"
			@on-click="onAccept"
		/>
	</div>
</template>
<script>
import ManagementService from '../../services/managementmaster.service';
import NotiModal from '@/components/Modal/NotificationModal/NotificationModal.vue';

import autoBankMixin from '@/mixin/autoBankMixin';
import autoAddressMixin from '@/mixin/autoAddressMixin';
import postcodeMixin from '@/mixin/postcodeMixin';

import CommonConst from '@/util/const';

const wanakana = require('wanakana');

export default {
	name: 'ManagementMaster',
	components: {
		NotiModal,
	},
	mixins: [autoBankMixin, autoAddressMixin, postcodeMixin],
	data() {
		return {
			notiModalId: 'user-edit-noti',
			notiContent: '',
			notiSingleBtn: '',
			model: {
				companyName: '',
				postcode: '',
				address: '',
				remark: '',
				phoneNumber: '',
				faxNumber: '',
				footerMailAddress: '',
				fromMailAddress: '',
				inquiryMail_1: '',
				inquiryMail_2: '',
				inquiryMail_3: '',
				// bankName: '',
				bankName: undefined,
				// bankBranchName: '',
				bankBranchName: undefined,
				bankAccountType: '',
				bankAccountHolder: '',
				bankAccountNumber: '',
				webSiteName: '',
				instagramAccount: '',
				facebookAccount: '',
				twitterAccount: '',
				ceoName: '',
				taxRate: '',
				// An added area
				postcodeAddressJapan: '',
				// An added area
			},
			loginUserId: '',
			managementId: '',
			editSuccessMessage: '変更が完了しました',
			editErrorMessages: '変更できません。',
			alertMessage: '',
			showAlert: false,
			alertType: '',
			bankAccountTypeOptions: [
				{
					value: '普通',
				},
				{
					value: '当座',
				},
			],
		};
	},
	methods: {
		checkPhoneNumber() {
			if (this.model.phoneNumber) {
				this.model.phoneNumber = this.model.phoneNumber.replaceAll('-', '');
			}
		},
		checkFaxNumber() {
			if (this.model.faxNumber) {
				this.model.faxNumber = this.model.faxNumber.replaceAll('-', '');
			}
		},
		checkPostCodeNumber() {
			if (this.model.postcode) {
				this.model.postcode = this.model.postcode.replaceAll('-', '');
			}
		},
		onKeyDown(event) {
			const acceptedKeys = [
				'Backspace',
				'Delete',
				'ArrowLeft',
				'ArrowRight',
				'Minus',
				'NumpadSubtract',
			];
			if (
				(isNaN(Number(event.key)) && !acceptedKeys.includes(event.code)) ||
				event.code == 'Space'
			) {
				event.preventDefault();
			}
		},
		onBankAccountHolderChange() {
			this.model.bankAccountHolder = wanakana.toKatakana(this.model.bankAccountHolder, {
				IMEMode: true,
			});
		},
		isNumeric(evt) {
			var theEvent = evt || window.event;
			var key = theEvent.keyCode || theEvent.which;
			key = String.fromCharCode(key);
			var regex = /[0-9]/;
			if (!regex.test(key)) {
				theEvent.returnValue = false;
				if (theEvent.preventDefault) theEvent.preventDefault();
			}
		},
		maxLengthCheck() {
			if (this.model.bankAccountNumber > 7) {
				this.model.bankAccountNumber = this.model.bankAccountNumber.slice(0, 7);
			}
		},
		onSave() {
			this.notiSingleBtn = false;
			this.notiContent = '管理マスタの情報を変更します。\nよろしいですか？';
			this.$bvModal.show(`${this.notiModalId}`);
		},

		onAccept() {
			// const model = { ...this.model };
			let model = { ...this.model };
			console.log("model accepte", model);
			model.postcodeAddressJapan = this.convertToHalfWidth(model.postcodeAddressJapan);
			let updatedManagementInfo = {
				account_holder: model.bankAccountHolder,
				account_number: model.bankAccountNumber,
				account_type: model.bankAccountType,
				address: model.address,
				bank_name: model.bankName,
				branch_name: model.bankBranchName,
				company_name: model.companyName,
				consumption_tax_rate: model.taxRate,
				contact_email_1: model.inquiryMail_1,
				contact_email_2: model.inquiryMail_2,
				contact_email_3: model.inquiryMail_3,
				facebook: model.facebookAccount,
				fax_number: model.faxNumber,
				footer_email_address: model.footerMailAddress,
				id: this.managementId,
				instagram: model.instagramAccount,
				phone_number: model.phoneNumber,
				// postal_code: model.postcode,
				postal_code: model.postcodeAddressJapan,
				remark: model.remark,
				represent_name: model.ceoName,
				sender_email_address: model.fromMailAddress,
				twitter: model.twitterAccount,
				update_user: this.loginUserId,
				username: this.model.loginId,
				web_site: this.model.webSiteName,
				company_seal: this.model.companySeal,
			};
			console.log("infoitz", updatedManagementInfo);
			this.updateNewManagementInfo(updatedManagementInfo);
		},

		async getManagementInfo() {
			let [response, error] = await ManagementService.getAll();
			console.log([response, error]);
			if (response) {
				let nullValueKeys = Object.keys(response).filter(
					(key) => response[key] === null
				);
				nullValueKeys.map((key) => {
					response[key] = '';
				});
				console.log('responseresponse', response[0]);
				let managementModel = {
					companyName: response[0].company_name,
					// postcode: response[0].postal_code,
					address: response[0].address,
					remark: response[0].remark,
					phoneNumber: response[0].phone_number,
					faxNumber: response[0].fax_number,
					footerMailAddress: response[0].footer_email_address,
					fromMailAddress: response[0].sender_email_address,
					inquiryMail_1: response[0].contact_email_1,
					inquiryMail_2: response[0].contact_email_2,
					inquiryMail_3: response[0].contact_email_3,
					bankName: response[0].bank_name,
					bankBranchName: response[0].branch_name,
					bankAccountType: response[0].account_type,
					bankAccountHolder: response[0].account_holder,
					bankAccountNumber: response[0].account_number,
					webSiteName: response[0].web_site,
					instagramAccount: response[0].instagram,
					facebookAccount: response[0].facebook,
					twitterAccount: response[0].twitter,
					ceoName: response[0].represent_name,
					taxRate: response[0].consumption_tax_rate,
					postcodeAddressJapan: response[0].postal_code,
				};
				this.managementId = response[0].id;
				this.model = { ...managementModel };
				console.log({ model: this.model });
			} else {
				console.log(error);
			}
		},

		async updateNewManagementInfo(newInfo) {
			console.log('newInfo', newInfo);
			let updatedInfo = this.deleteNullFields(newInfo);
			console.log('updatedInfo after delete', updatedInfo);
			let [response, error] = await ManagementService.update(
				this.managementId,
				updatedInfo
			);
			if (response) {
				console.log('Edit Management Master Success!');
				this.notiSingleBtn = true;
				this.notiContent = '最新情報に変更されました。';
				this.$bvModal.show(`${this.notiModalId}`);
			} else {
				this.showAlert = true;
				this.alertType = 'danger';
				this.alertMessage = this.editErrorMessages;
			}
		},

		deleteNullFields(obj) {
			console.log("objobjobj", obj);

			for (let key in obj) {
				if ((obj[key] == undefined || obj[key] == '') && !CommonConst.IGNORE_FIELD.includes(key)) {
					if (key === 'account_number') {
						obj[key] = null;
					} else {
						delete obj[key];
					}
				}
			}
			return obj;

			// for (let key in obj) {
			// 	if ((obj[key] == undefined) && !ignoreField.includes(key)) {
			// 		delete obj[key];
			// 	}
			// 	else if (obj[key] == null && !ignoreField.includes(key)) {
			// 		if ((this.infoFromDatabase[key] === obj[key])) {
			// 			delete obj[key];
			// 		}
			// 	}
			// }
			// return obj;
		},

		onAlertVisibleChange(data) {
			this.showAlert = data;
		},
	},

	mounted() {
		this.getManagementInfo();
		// this.getManagementInfoTest();
	},
};
</script>
